import { render, staticRenderFns } from "./DeptPicker.vue?vue&type=template&id=146d7bca&scoped=true"
import script from "./DeptPicker.vue?vue&type=script&lang=js"
export * from "./DeptPicker.vue?vue&type=script&lang=js"
import style0 from "./DeptPicker.vue?vue&type=style&index=0&id=146d7bca&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "146d7bca",
  null
  
)

export default component.exports